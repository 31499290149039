import {useRef} from "react";
import emailjs from '@emailjs/browser';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Contact = () => {

  const form = useRef()

  const sendEmail = (e) => {
 
    e.preventDefault();
    toast.success('Ihre nachricht wurde erfolgreich gesendet !', {
      position: toast.POSITION.TOP_RIGHT
    });
 
    emailjs.sendForm('service_wgi1zwh', 'template_dy48g8h', form.current, 'Zcwp9rQpB9XiiQ8Tk')
      .then((result) => {
          if (result.text == 'ok') {
            toast.success('Ihre nachricht wurde erfolgreich gesendet !', {
              position: toast.POSITION.TOP_RIGHT
            });
          }
      }, (error) => {
        toast.success('Die Nachricht konnte nicht gesendet werden !', {
          position: toast.POSITION.TOP_RIGHT
        });
      });

    e.target.reset()
  
  };

  return (
    <div id="contact-us" className="section section-right bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h5 className="sm-head wow fadeInUp" data-wow-delay="0.1s">
            kontakt
            </h5>
            <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            kontaktieren Sie  <span className="p-color">mich</span>
            </h2>
          </div>
          <div className="col-lg-4 col-md-12 pb-30">
            <h3 className="wow fadeInUp" data-wow-delay="0.3s">
              Rufen Sie mich an
            </h3>
            <p className="phone wow fadeInUp" data-wow-delay="0.4s">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-telephone"
                viewBox="0 0 16 16"
              >
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>
              +49 176 200 20 139
            </p>
            <p className="phone wow fadeInUp" data-wow-delay="0.5s">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-telephone"
                viewBox="0 0 16 16"
              >
                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
              </svg>
              +49 30 200 23 93
            </p>
            <h3 className="wow fadeInUp" data-wow-delay="0.6s">
              E-mail.
            </h3>
            <p className="wow fadeInUp" data-wow-delay="0.7s">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-envelope"
                viewBox="0 0 16 16"
              >
                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
              </svg>
              info@it-testing-services.com
            </p>
            <h3 className="wow fadeInUp" data-wow-delay="0.8s">
              Standort.
            </h3>
            <p className="wow fadeInUp" data-wow-delay="0.9s">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-geo-alt"
                viewBox="0 0 16 16"
              >
                <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              Haßlingerweg 10, 13409 Berlin
            </p>
          </div>
          <div  className="col-lg-8 col-md-12 pt-md-set">
            <div  className="form">
              <form onSubmit={sendEmail} ref={form} >
              <div  className="row">
                <div className="col-12">
                  <h3 className="wow fadeInUp" data-wow-delay="0.1s">
                    Nachricht schicken
                  </h3>
                </div>
                <div
                  className="col-md-6 mb-3 wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <label htmlFor="Name" id="Name" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="username"
                    id="username"
                    required
                  
                    placeholder=""          
                    style={{
                      fontSize: "1rem",
                    }}
                  />
                  <div className="invalid-feedback"> Name is required.</div>
                </div>

                <div
                  className="col-md-6 mb-3 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <label htmlFor="phone" className="form-label">
                    Handynummer
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="handy"
                    id="handy"
                    required
                    placeholder=""            
                    style={{ fontSize: "1rem" }}
                  />
                  <div className="invalid-feedback">
                    {" "}
                    Valid phone is required.
                  </div>
                </div>

                <div
                  className="col-md-6 mb-3 wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <label htmlFor="email" className="form-label">
                    E-mail
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    id="email"
                    required
                    placeholder=""
                    //value=""
              
                    style={{ fontSize: "1rem" }}
                  />
                  <div className="invalid-feedback">
                    {" "}
                    Valid email is required.
                  </div>
                </div>

                <div
                  className="col-md-6 mb-3 wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <label htmlFor="subject" className="form-label">
                    Betreff
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    required
                    id="subject"
                    placeholder=""
               
                    style={{ fontSize: "1rem" }}
                  />
                  <div className="invalid-feedback"> Subject is required.</div>
                </div>

                <div
                  className="col-md-12 mb-3 wow fadeInUp"
                  data-wow-delay="0.6s"
                >
                  <label htmlFor="message" className="form-label">
                    Nachricht
                  </label>
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows="5"
                    placeholder=""
                    style={{ fontSize: "1rem" }}
                  ></textarea>
                  <div className="invalid-feedback"> Message is required.</div>
                </div>

                <div className="col-md-12 wow fadeInUp" data-wow-delay="0.7s">
                  <button type="submit" className="btn btn-primary">
                    Nachricht schicken
                  </button>{" "}
                  <ToastContainer />
                </div>
                </div>
              </form>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
