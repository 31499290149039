import Certificate from "./pages/certificate";
import Experience from "./pages/experience";
import Footer from "./pages/footer";
import Header from "./pages/header";
import Navbar from "./pages/navbar";
import Services from "./pages/services";
import Skills from "./pages/skills";

import "./assets/js/bootstrap.bundle.min.js";
import Contact from "./pages/contact";
function App() {
  return (
    <>
      <div className="wrapper">
        <Navbar />
        <Header />
        <Services />
        <Experience />
        {/* <Skills /> */}
        <Certificate />
        <Contact />
      </div>
      <Footer />
    </>
  );
}

export default App;
