import { useEffect } from "react";

import customJs from "../assets/js/custom";
const Navbar = () => {
  useEffect(() => {
    customJs();
  }, []);
  return (
    <nav className="navbar navbar-expand-lg">
      <a className="navbar-brand wow fadeInUp" data-wow-delay="0.1s" href="" >
      </a>
      <button
        className="navbar-toggler wow fadeInUp"
        data-wow-delay="0.2s"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
      </button>

      <div
        className="collapse navbar-collapse wow fadeInUp"
        data-wow-delay="0.3s"
        id="navbarNavAltMarkup"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href="#home">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#services">
            Leistungen
            </a>
          </li>
          {/* <li className="nav-item"><a className="nav-link" href="#profile">Profile</a></li> */}
          <li className="nav-item">
            <a className="nav-link" href="#experience-reference">
            Erfahrung-und-Referenzen
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#contact-us">
            Kontaktformular
            </a>
          </li>
          <li className="nav-item">
            
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
