const Services = () => {
  return (
    <div id="services" className="section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h5 className="sm-head wow fadeInUp" data-wow-delay="0.1s">
            Leistungen
            </h5>
            <h2 className="wow fadeInUp" data-wow-delay="0.3s">
             Diese Leistungen biete ich Ihnen für Ihr IT-Projekt an:
            </h2>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="s-card wow fadeInUp" data-wow-delay="0.7s">
              <h4>
                <span className="p-color">Test</span>automatisierung{" "}
              </h4>
              <h1>01</h1>
              <p>
              Verbessern Sie u. a. durch Testautomatisierung sowohl Ihre Webtechnologien als auch Desktop-Applikationen (z. B. SAP GUI). Vertrauen Sie auf eine umfangreiche Beratung in den Bereichen Software-Entwicklung mit objektorientierten Technologien und Testmanagement.
              Von der Konzeption bis zur Umsetzung sowie anschließendem Support oder Schulungen in Cucumber, Selenium und Appium. Auch explorative Testing-Workshops kann ich gerne umsetzen.
              </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="s-card wow fadeInUp" data-wow-delay="0.5s">
              <h4>
                <span className="p-color">Test</span>management
              </h4>
              <h1>02</h1>
              <p>
              Unterstützung und Durchführung Ihrer Testprojekte, auch unter Berücksichtigung der ISTQB Vorgaben.
              <br></br>Testmanagement - Vorteile für Sie:
geringere Kosten und weniger Ausfälle: mit professionellem Testmanagement lassen sich Ihre Projektkosten stark reduzieren und dadurch wird auch das Risiko von Systemausfällen und damit zusammen hängende Fehlerkosten startk verringert.

Professionelles Testmanagement ermöglicht das ständige Einsehen der Fortschritte. Notwendige Interventionen können so zeitnah erkannt und umgesetzt werden.
              </p>
            </div>
          </div>
       
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="s-card wow fadeInUp" data-wow-delay="0.9s">
              <h4>
                <span className="p-color">Deployment</span> Pipelines(CI/CD){" "}
              </h4>
              <h1>03</h1>
              <p>
              CI/CD macht die Softwareentwicklung effizienter, indem Probleme so früh wie möglich erkannt werden. Durch den sogenannten „Linksshift“, also indem Interaktionen vorverlagert werden und Rückmeldungen früher erfolgen, treten Fehler schneller zutage. Der Aufbau einer automatisierten Pipeline hilft Ihnen, diese Techniken in die Praxis umzusetzen.
              Gerne unterstütze ich Sie beim Aufbau der CI/CD Pipelines sowie der Anbindung der Testautomatisierung in den bestehenden Pipelines.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
