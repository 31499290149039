const Skills = () => {
  return (
    <>
      <div style= {{color:"black", fontSize:"26px", fontWeight: "bold",
        lineHeight: "28px", margin: "0 0 28px"}}>
       Programmiersprachen:-
      </div>  
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
        <div className="exp-progress">
          <h3>
            <span>95%</span>Python
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "95%",
              }}
              aria-valuenow="83"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
        <div className="exp-progress">
          <h3>
            <span>90%</span>Java
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{
                width: "85%",
              }}
              aria-valuenow="63"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.3s">
        <div className="exp-progress">
          <h3>
            <span>70%</span>JavaScript und Node.js
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "70%" }}
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.4s">
        <div className="exp-progress">
          <h3>
            <span>80%</span>Bash-Skripting
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "80%" }}
              aria-valuenow="55"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.5s">
        <div className="exp-progress">
          <h3>
            <span>60%</span>Go
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "66%" }}
              aria-valuenow="63"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <hr></hr>
      <br></br>
     
      
      <div style= {{color:"black", fontSize:"26px", fontWeight: "bold",
        lineHeight: "28px", margin: "0 0 28px"}}>
       Bibliotheken und Tools:-
      </div>          
      

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.6s">
        <div className="exp-progress">
          <h3>
            <span>95%</span>Selenium
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "95%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>95%</span>Appium
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "95%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>80%</span>Cypress
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "80%" }}
              aria-valuenow="80"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>94%</span>WebdriverIO
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "94%" }}
              aria-valuenow="94"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>90%</span>Restassured
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "90%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>90%</span>Karate(Karate-Labs)
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "90%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>98%</span>Cucumber
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "98%" }}
              aria-valuenow="98"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>90%</span>Apache JMeter
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "90%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>85%</span>Gatling
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "90%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>


      <hr></hr>
      <br></br>
      <div style= {{color:"black", fontSize:"26px", fontWeight: "bold",
        lineHeight: "28px", margin: "0 0 28px"}}>
       CI/CD Pipelines:-
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>95%</span>Jenkins
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "95%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>85%</span>GitHub Actions
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "95%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>80%</span>GitLab
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "95%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>90%</span>Docker
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "90%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>75%</span>Kubernetes (K8s)
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "75%" }}
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>90%</span>SonarQube
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "90%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      

      <hr></hr>
      <br></br>
      <div style= {{color:"black", fontSize:"26px", fontWeight: "bold",
        lineHeight: "28px", margin: "0 0 28px"}}>
       Cloud Computing:-
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>95%</span>Amazon Web Services
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "95%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>80%</span>Microsoft Azure
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "80%" }}
              aria-valuenow="80"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>50%</span>Google Cloud Platform
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "60%" }}
              aria-valuenow="60"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <hr></hr>
      <br></br>
      <div style= {{color:"black", fontSize:"26px", fontWeight: "bold",
        lineHeight: "28px", margin: "0 0 28px"}}>
       Testmanagement und Tools:-
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>92%</span>Jira
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "92%" }}
              aria-valuenow="92"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>92%</span>Confluence
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "92%" }}
              aria-valuenow="92"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>95%</span>Xray
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "95%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>90%</span>Zephyr
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "90%" }}
              aria-valuenow="90"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>85%</span>Allure TestOps
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "85%" }}
              aria-valuenow="85"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>70%</span>HP ALM
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "70%" }}
              aria-valuenow="70"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>










      <hr></hr>
      <br></br>
      <div style= {{color:"black", fontSize:"26px", fontWeight: "bold",
        lineHeight: "28px", margin: "0 0 28px"}}>
       Sprachen:-
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>92%</span>Deutsch
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "95%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>85%</span>Englisch
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "85%" }}
              aria-valuenow="85"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>

      <div className="col-md-4 wow fadeInUp" data-wow-delay="0.7s">
        <div className="exp-progress">
          <h3>
            <span>95%</span>Arabisch
          </h3>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "85%" }}
              aria-valuenow="95"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>


              





    </>
  );
};

export default Skills;
