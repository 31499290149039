const Header = () => {
  return (
    <div id="home" className="header">
      <div className="middle-content">
        <div className="container">
          <h1 className="wow fadeInUp" data-wow-delay="0.1s">
            <small>Herzlich Willkommen auf IT-Testing-Services</small>
        
            <span className="p-color wow fadeInUp" data-wow-delay="0.3s">
              Yousef Alhajsalem 
              <br></br>
              ISTQB Certified Tester
              <br></br>
               und Test Automation 
               <br></br>Engineer
            </span>
          </h1>
          <br>
              </br>
          <p className="wow fadeInUp" data-wow-delay="0.5s">
          Seit über 13 Jahren berate und begleite ich Unternehmen in allen Themenbereichen der IT. Dabei habe ich mich auf die Bereiche Testautomatisierung, CI/CD pipelines und Testmanagement spezialisiert.
          </p>
        </div>
      </div>
      <figure>
        <picture>
          <source
            media="(max-width: 480px)"
            srcset="/yousef102.jpg"
            data-src="/yousef102.jpg"
          />
          <source
            media="(max-width: 1024px)"
            srcset="/yousef11.jpg"
            data-src="/yousef11.jpg"
          />
          <source
            media="(min-width: 1025px)"
            srcset="/yousef8.jpg"
            data-src="/yousef8.jpg"
          />
          <img
            src="/header-lg-min.jpg"
            data-src-base="/"
            data-src="{xs:/header-xsm-min.jpg,md:/header-md-min.jpg,xl:/header-lg-min.jpg}"
            alt=""
          />
        </picture>
      </figure>
    </div>
  );
};

export default Header;
