const Certificate = () => {
  return (
    <div className="section">
      
      <div className="container">
        <div className="row justify-content-center">
        <div>
        </div>
      <h3 className="wow fadeInUp" data-wow-delay="0.2s">
      Qualifikation <span className="p-color">und</span>{" "}
      Zertifizierungen:- 
      </h3>
      <div>
      <br></br>
      <ul>
      <li style={{color: 'black', fontSize:"18px", fontWeight: "bold",
        lineHeight: "25px", margin: "0 0 20px"}}> <span className="p-color">&#10003;</span> <span className="p-color">ISTQB®</span> Certified Tester - Foundation Level (06/2013)</li>
      <li style={{color: 'black', fontSize:"18px", fontWeight: "bold",
        lineHeight: "25px", margin: "0 0 20px"}}> <span className="p-color">&#10003;</span> <span className="p-color">IREB®</span> Certified Professional for Requirements Engineering - Foundation Level (07/2014)</li>
      <li style={{color: 'black', fontSize:"18px", fontWeight: "bold",
        lineHeight: "25px", margin: "0 0 20px"}}> <span className="p-color">&#10003;</span> <span className="p-color">ISTQB®</span>  Agile Certified Tester (09/2015)</li>
      <li style={{color: 'black', fontSize:"18px", fontWeight: "bold",
        lineHeight: "25px", margin: "0 0 20px"}}> <span className="p-color">&#10003;</span> <span className="p-color">AWS®</span> Certified Solutions Architect – Associate (05/2017)</li>
      <li style={{color: 'black', fontSize:"18px", fontWeight: "bold",
        lineHeight: "25px", margin: "0 0 20px"}}> <span className="p-color">&#10003;</span><span className="p-color">AWS®</span> Certified Developer – Associate (12/2017)</li>
      <li style={{color: 'black', fontSize:"18px", fontWeight: "bold",
        lineHeight: "25px", margin: "0 0 20px"}}> <span className="p-color">&#10003;</span> <span className="p-color">ISTQB®</span> Certified Tester – Test Automation Engineer (07/2020)</li>
      <li style={{color: 'black', fontSize:"18px", fontWeight: "bold",
        lineHeight: "25px", margin: "0 0 20px"}}> <span className="p-color">&#10003;</span><span className="p-color"> ISTQB®</span>  Certified Mobile Application Tester (11/2022)</li>
    </ul>

      </div>
      <div
            className="col-lg-2 col-md-2 col-6 wow fadeInUp"
            data-wow-delay="0.5s"
          >
            {" "}
            <img className="st-img" src="/logo-005.jpg" alt="" />
          </div>

          <div
            className="col-lg-2 col-md-2 col-6 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            {" "}
            <img className="st-img" src="/logo-003.jpg" alt="" />
          </div>


          <div
            className="col-lg-2 col-md-2 col-6 wow fadeInUp"
            data-wow-delay="0.4s"
          >
            {" "}
            <img className="st-img" src="/logo-004.jpg" alt="" />
          </div>
   
     
        
          <div
            className="col-lg-2 col-md-2 col-6 wow fadeInUp"
            data-wow-delay="0.1s"
          >
            {" "}
            <img className="st-img" src="/logo-001.jpg" alt="" />
        
          </div>
          <div
            className="col-lg-2 col-md-2 col-6 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            {" "}
            <img className="st-img" src="/logo-002.jpg" alt="" />
          </div>
        

          <div
            className="col-lg-2 col-md-2 col-6 wow fadeInUp"
            data-wow-delay="0.6s"
          >
            {" "}
            <img className="st-img" src="/logo-006.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
