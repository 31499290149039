import Skills from "./skills";

const Experience = () => {
  return (
    <div id="experience-reference" className="section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h5 className="sm-head wow fadeInUp" data-wow-delay="0.1s">
          
            </h5>
            <h2 className="wow fadeInUp" data-wow-delay="0.2s">
             Beruflicher<span className="p-color"> Werdegang</span>{" "}
              
            </h2>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="resume-box">
              <ul>
                <li>
                  <div className="icon wow fadeInUp" data-wow-delay="0.1s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    10/2018 bis 12/2022 (4 Jahre und 2 Monate)
                  </span>
                  <h5
                    className="poppins-font wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    Continental AG Regensburg <span></span>
                  </h5>

                  <div className="icon wow fadeInUp" data-wow-delay="0.4s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    10/2018 bis 12/2018
                  </span>
                  <h5
                    className="poppins-font wow fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    System Test Engineer Backend-Services
                    <span></span>
                  </h5>
                  <div className="icon wow fadeInUp" data-wow-delay="0.7s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.8s"
                  >
                    01/2019 bis 12/2022
                  </span>
                  <h5
                    className="poppins-font wow fadeInUp"
                    data-wow-delay="0.9s"
                  >
                    Technical Lead System Test <span></span>
                  </h5>
                

                  <ul className="substyle wow fadeInUp" data-wow-delay="1.2s">
                    <li>
                    Design als auch Entwicklung von System-Tests für End-to-end Cloud Solutions und
                    damit verbundene Pflege und Weiterentwicklung eines existierenden Test-Automatisierungs-Setups{" "}
                    </li>
                    <li>Entwicklung von Tests für Mobile-Applications (Android und iOS)</li>
                    <li>
                    Konzeption und Implementierung von Test­szenarien, welche die Performance des Teil- oder Gesamt­systems sowie das System­verhalten unter Last testen{" "}
                    </li>
                    <li>Verantwortung für alle Backend-Services</li>
                    <li>Aufbau von Jenkins Pipelines</li>
                  </ul>
                </li>
                <li>
                  <div className="icon wow fadeInUp" data-wow-delay="0.1s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    01/2016 bis 09/2018 (2 Jahre und 9 Monate)
                  </span>
                  <h5
                    className="poppins-font  wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    ProSiebenSat.1 Group Unterföhring<span></span>
                  </h5>

                  <div className="icon wow fadeInUp" data-wow-delay="0.4s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    01/2016 bis 09/2018
                  </span>
               
                  <h5
                    className="poppins-font  wow fadeInUp"
                    data-wow-delay="0.6s"
                  >
                    Senior Software Test Engineer
                    <span></span>
                  </h5>

                  <ul className="substyle wow fadeInUp" data-wow-delay="0.8s">
                    <li>
                    Verantwortlich im Agile Project Teams für die Testaktivitäten{" "}
                    </li>
                    <li>
                    Weiterentwicklung der bestehenden Testautomationsarchitektur{" "}
                    </li>
                    <li>Entwicklung und Konzipierung von neuen automatisierten Testfälle</li>
                    <li>Erweitern und Pflege von Testautomatisierung-Infrastruktur</li>
                    <li>Auswählen und Bewerten von verschiedenen Testtools</li>
                    <li>
                    Testen von Systemschnittstellen und Microservices{" "}
                    </li>
                    <li>
                    Identifikation und Dokumentation aller gefundenen Fehler{" "}
                    </li>
                    <li>Aufbau und Pflege von Continuous Integration/Delivery Pipelines</li>
                    <li>Unterstützung der Entwickler bei der Nachstellung und Behebung von Fehlern</li>
                  </ul>
                </li>

                <li>
                  <div className="icon wow fadeInUp" data-wow-delay="0.1s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    10/2011 bis 12/2015(4 Jahre und 2 Monate)
                  </span>
                  <h5
                    className="poppins-font wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    Nash Technologies GmbH Nürnberg<span></span>
                  </h5>

                  <div className="icon wow fadeInUp" data-wow-delay="0.4s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    10/2011 bis 04/2014
                  </span>
                  <h5 className="poppins-font">
                     Einsatz bei Alcatel Lucent Deutschland AG in Nürnberg
                    <span></span>
                  </h5>
                  <div className="icon wow fadeInUp" data-wow-delay="0.6s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                
                  <h5
                    className="poppins-font wow fadeInUp"
                    data-wow-delay="0.8s"
                  >
                    Software System Test Ingenieur für UMTS 3GPP
                    <span></span>
                  </h5>
                  <ul className="substyle wow fadeInUp" data-wow-delay="1s">
                    <li>Verifikation von Funktionalitäten über verschiedene Netzelemente inkl. Problemanalyse</li>
                    <li>
                    Testfälle erstellen und das Vorgehen planen{" "}
                    </li>
                    <li>
                    Testfall-Durchführung (manuell oder automatisiert)
                    </li>
                    <li>
                    Fehlersuche mit Hilfe verschiedener Protokollmonitore auf den Schnittstellen
                    </li>
                    <li>
                    Beurteilung der Ergebnisse und das entsprechende Reporting{" "}
                    </li>
                    <li>
                    Zusammenarbeit mit Entwicklung und anderen Testinstanzen bei der Fehleranalyse und Verifikation
                    </li>
                  </ul>
                  <div className="icon wow fadeInUp" data-wow-delay="0.4s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    05/2014 bis 11/2014
                  </span>
                  <h5 className="poppins-font">
                  Einsatz bei Sky Deutschland AG in Unterföhring
                    <span></span>
                  </h5>
                  <div className="icon wow fadeInUp" data-wow-delay="0.6s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                
                  <h5
                    className="poppins-font wow fadeInUp"
                    data-wow-delay="0.8s"
                  >
                    Software Tester (UAT) für IOS, Android Apps und Weboberflächen (Skygo- Skyonline)
                    <span></span>
                  </h5>
                  <ul className="substyle wow fadeInUp" data-wow-delay="1s">
                    <li>Konzeption und Planung von Software-Tests im mobilen Umfeld</li>
                    <li>
                    Auswahl von notwendigen Testmethoden und Testgeräten{" "}
                    </li>
                    <li>
                    Einrichten und Konfiguration der notwendigen mobilen Testgeräte
                    </li>
                    <li>
                    Erarbeitung von Testfällen/ Testspezifikationen
                    </li>
                    <li>
                    Durchführung manueller und automatisierter Tests für die Abnahme{" "}
                    </li>
                    <li>
                    Ergebnisanalyse und überwachen des Testfortschritts                   
                     </li>
                  </ul>
                  <div className="icon wow fadeInUp" data-wow-delay="0.4s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <span
                    className="time open-sans-font wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    12/2014 bis 12/2015
                  </span>
                  <h5 className="poppins-font">
                  Einsatz bei Intel Mobile Communications in Nürnberg
                    <span></span>
                  </h5>
                  <div className="icon wow fadeInUp" data-wow-delay="0.6s">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-briefcase"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                
                  <h5
                    className="poppins-font wow fadeInUp"
                    data-wow-delay="0.8s"
                  >
                    Software Integration Tester für L1 LTE Functional Simulator
                    <span></span>
                  </h5>
                  <ul className="substyle wow fadeInUp" data-wow-delay="1s">
                    <li>Durchführung von Tests in einer Matlab-Umgebung</li>
                    <li>
                    Durchführung von Tests in einer C++ basierten Simulationsumgebung
                    </li>
                    <li>
                    Validierung der Ergebnisse in Functional Simulator (Rel 11) basierend auf System C
                    </li>
                    <li>
                    Fehlerberichte erstellen und mit dem Team bei der Fehlerbehebung zusammenarbeiten
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <Skills />
        </div>
      </div>
    </div>
  );
};

export default Experience;
